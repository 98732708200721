.bgcLanding{
    width : 100vw;
    height : 100vh;
    background-color:#fff;
    text-align: center;
}
.signContainer{
    width: 100%;
    height: 100%;
    text-align:left;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
}

.landingTitle{
    color: #0a0a0a;
    font-size: calc(20px + 1vmin);
    border-bottom: 1px solid #C0C0C0;
    padding-bottom: 15px;
}
.landingBtn{
    color:#000000;
    font-size: calc(15px + 1vmin);
    min-height: 54px;
    text-align: center;
    margin-top: 20px;
}
.signDiv{
    width: 100%;
    height: 70%;
    border: 1px solid #C0C0C0
}

.btnStyle{
    width: 100%;
    height: 54px;
    line-height: 54px;
    margin:0 auto;
    padding: 0;
    border: none;
    font-size: calc(15px + 1vmin) ;
    margin-bottom: 25px;

}


@media screen and (max-width:1000px){
    .signContainer{
        width: 85vw
    }

}
@media screen and (min-width: 1000px){
    .signContainer{
        width: 30vw
    }

}


