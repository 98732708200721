.bgcLanding{
    width : 100vw;
    height : 100vh;
    background-color:#fff;
    text-align: center;
}
.anwerContainer{
    width: 85vw;
    height: 100%;
    text-align:left;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
}
.answerTitleTxt{
    color: #0a0a0a;
    font-size: calc(25px + 1vmin);
}
.titleContainer {
    position: relative;
}
.answerTitle{
    float: left;
    width: 75%;
    word-wrap: break-word;
}
.answerTitleCount{
    float: left;
    text-align: right;
    width: 25%
}
.bodyContainer{
    margin-top: 30px;
    flex: 1;
    flex-direction: column
}

.landingBtn{
    font-size: calc(15px + 1vmin);
    min-height: 54px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px
}
.lableContainer{
    min-height: 70%;
    max-height: 300px;;
}
.lableTextArea{
    resize: none;
    font-size: 20px;
}
.btnStyle{
    width: 100%;
    height: 54px;
    line-height: 54px;
    margin:0 auto;
    background-color:#342C2B;
    color: #fff;
    padding: 0;
    border: none;
    font-size: calc(15px + 1vmin) 

}
.halfBtnStyle{
    width: 49.5%;
    height: 54px;
    line-height: 54px;
    margin:0 auto;
    background-color:#342C2B;
    padding: 0;
    border: none;
    font-size: calc(15px + 1vmin);
    color: #fff;

}

.landingBtn div button:nth-child(1){
    margin-right: 3px;
}


@media screen and (max-width:1000px){
    .anwerContainer{
        width: 85vw
    }

}
@media screen and (min-width: 1000px){
    .anwerContainer{
        width: 30vw
    }

}


