body{
    margin: 0;
    padding: 0;
}
.loginInput {
    width: 70%;
    height: 30px; 
    font-size: 15px;
    padding-left: 7px
}
.homeBgcLanding{
    width : 100vw;/*200vw*/
    height : 100vh;
    background-color:#342C2B;
    /* text-align: center; */
    margin: 0 auto;
}
.textBgcLanding{
    width : 100vw;/*200vw*/
    height : 100vh;
    background-color:#342C2B;
    text-align: center;
    color: #fff;
}
.lanchingDiv{
    display:inline-flex;
    text-align:left;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    
}
.bgcWhite{
    background-color:#fff;
}

.homeLandingTitle{
    color: #fff;
    font-size: calc(30px + 1vmin);
}

.textLandingTitle{
    margin: 0;
    width: 100%;
    color: #fff;
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    transform: translate(-50% -50%);
}

.landingDate{
    color: #ECDAC5;
    font-size: calc(13px + 1vmin);
}

.landingSummary{
    margin-top: 10%;
    color: #fff;
    font-size: calc(16px + 1vmin);
    line-height: 30px
}

.landingLable{
    width: 26%;
    color:#ECDAC5;
    font-size: calc(16px + 1vmin);
    float: left;
    margin-bottom: 30px;
    line-height: 36px;
}
.landinginfo{
    color:black;
    font-size: calc(16px + 1vmin);
}
.homeLandingBtn{
    
    background-color:#ECDAC5;
    min-height: 54px;
    text-align: center;
    margin-bottom: 30px;
}
.lineDiv{
    width: 100%;
    border-bottom: 1px solid white;
}
.rowInfo{
    margin-top: 30px
}
.linkBtnStyle{
    text-decoration:   none;
}
.homeBtnStyle{
    color:#000000;
    font-size: calc(16px + 1vmin);
    width: 90%;
    height: 54px;
    line-height: 54px;
    margin: 0 auto;

}


@media screen and (max-width:1025px){
    .lanchingDiv{
        width: 85vw
    }

    .homeBgcLanding{
        width : 85vw;/*200vw*/
    }
    

    
}
@media screen and (min-width: 1025px){
    .lanchingDiv{
        width: 50vw
    }

    .homeBgcLanding{
        width : 30vw;/*200vw*/
    }

    .landingLable{
        width: 26%;
    }

}