.completeBgcLanding{
    width : 100vw;/*200vw*/
    height : 100vh;
    background-color:#342C2B;
    text-align: center;
    color: #fff;
}
.lanchingDiv{
    display:inline-flex;
    text-align:left;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    
}
.bgcWhite{
    background-color:#fff;
}

.homeLandingTitle{
    color: #fff;
    font-size: calc(30px + 1vmin);
}

.completeLandingTitle{
    margin: 0;
    width: 100%;
    color: #fff;
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    transform: translate(-50% -50%);
}